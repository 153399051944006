import { QueryClient, QueryKey } from '@tanstack/react-query';
import { getBaseURL } from './utilFunctions';
import axiosFetch from './axiosConfig';
import { TAdjustment, TBrand, TCompanyAndPayments, TDailySummaryProps, TEventTypes, TExpenditure, TExpenditureCategory, TGuests, THotel, THotelEvents, THotelLocation, THotelRoom, THotelRoomStatus, TItem, TPayment, TPurchase, TReservations, TRoomType, TSales, TSuppliers, TUser } from './types';
import { TDailySummary } from './types';

export const fetchData=async <T>(urlLink:string,queryClient: QueryClient,queryKey: QueryKey,staleTime:number=300000,cacheTime:number=400000)=>{
    return await queryClient.fetchQuery({
        queryKey:queryKey,
        queryFn:()=>axiosFetch<T>('get',
            `${getBaseURL()}/${urlLink}`, {}).then((data)=>{
            return data.data?.operatedData;
        }).catch((error: any)=>{
            throw new Error('Fetch Failed');
        }),
        staleTime:staleTime,
        cacheTime:cacheTime,
        networkMode:'always'
    });
}
export const hotelsListQuery = async (queryClient: QueryClient, ownerId:string) => {
    return await fetchData<THotel[]>(`hotels/get_hotels_list?ownerId=${ownerId}`, queryClient, ['hotelsList']);
};
export const hotelLocationsListQuery = async (queryClient: QueryClient, hotelId:number) => {
    return await fetchData<THotelLocation[]>(`locations/get_locations_list?hotelId=${hotelId}`, queryClient, ['hotelLocationsList',hotelId]);
};
export const hotelLocationRoomsTypeQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TRoomType[]>(`room_type/get_room_types?hotelLocationId=${hotelLocationId}`, queryClient, ['roomTypesList',hotelLocationId]);
};
export const hotelLocationRoomsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<THotelRoom[]>(`hotel_rooms/get_rooms_list?hotelLocationId=${hotelLocationId}`, queryClient, ['roomsList',hotelLocationId]);
};
export const hotelLocationGuestsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TGuests[]>(`booking_reservations/guests_list?hotelLocationId=${hotelLocationId}`, queryClient, ['guestsList',hotelLocationId]);
};
export const hotelEventTypesListQuery = async (queryClient: QueryClient,hotelLocationId:number) => {
    return await fetchData<TEventTypes[]>(`booking_reservations/get_event_types?hotelLocationId=${hotelLocationId}`, queryClient, ['eventTypesList',hotelLocationId]);
};
export const hotelLocationsDetailedListQuery = async (queryClient: QueryClient, hotelId:number) => {
    return await fetchData<THotelLocation[]>(`locations/get_locations_detailed_list?hotelId=${hotelId}`, queryClient, ['hotelLocationsListDetailed',hotelId]);
};
export const locationStaffQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TUser[]>(`users/get_users?hotelLocationId=${hotelLocationId}`, queryClient, ['staffList']);
};
export const hotelRoomsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<THotelRoom[]>(`hotel_rooms/get_rooms_list_detailed?hotelLocationId=${hotelLocationId}`, queryClient, ['hotelRoomsList',hotelLocationId]);
};
export const hotelLocationReservationsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TReservations[]>(`booking_reservations/get_reservations?hotelLocationId=${hotelLocationId}`, queryClient, ['hotelReservations',hotelLocationId]);
};
export const hotelEventsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<THotelEvents[]>(`booking_reservations/get_hotel_events?hotelLocationId=${hotelLocationId}`, queryClient, ['hotelEventsList',hotelLocationId]);
};
export const expenseCategoriesListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TExpenditureCategory[]>(`expenditure/get_expenditure_categories?hotelLocationId=${hotelLocationId}`, queryClient, ['expenditureCategoryList',hotelLocationId]);
};
export const expenditureListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TExpenditure[]>(`expenditure/get_expenditure_list?hotelLocationId=${hotelLocationId}`, queryClient, ['expenditureList',hotelLocationId]);
};
export const itemsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TItem[]>(`items/get_all_items?hotelLocationId=${hotelLocationId}`, queryClient, ['itemsList',hotelLocationId]);
};
export const itemBrandsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TBrand[]>(`items/get_item_brands?hotelLocationId=${hotelLocationId}`, queryClient, ['brandsList',hotelLocationId]);
};
export const suppliersListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TSuppliers[]>(`items/get_suppliers?hotelLocationId=${hotelLocationId}`, queryClient, ['suppliersList',hotelLocationId]);
};
export const purchaseListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TPurchase[]>(`items/get_purchase_list?hotelLocationId=${hotelLocationId}`, queryClient, ['purchaseList',hotelLocationId]);
};
export const adjustmentsListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TAdjustment[]>(`items/get_adjustments?hotelLocationId=${hotelLocationId}`, queryClient, ['adjustmentsList',hotelLocationId]);
};
export const salesListQuery = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<TSales[]>(`items/get_sales_items?hotelLocationId=${hotelLocationId}`, queryClient, ['salesList',hotelLocationId]);
};
export const paymentsListQuery = async (queryClient: QueryClient, activityId:number) => {
    return await fetchData<TPayment[]>(`booking_reservations/get_reservation_payments?activityId=${activityId}`, queryClient, ['reservationPayments',activityId]);
};
export const eventsPaymentsListQuery = async (queryClient: QueryClient, activityId:number) => {
    return await fetchData<TPayment[]>(`booking_reservations/get_hotel_events_payments?activityId=${activityId}`, queryClient, ['hotelEventsPayments',activityId]);
};
export const bookedRoomsListQuery = async (queryClient: QueryClient, bookingType:string,startDate:string,endDate:string,hotelLocationId:number) => {
    return await fetchData<TReservations[]>(`booking_reservations/get_booked_reservation_rooms?bookingType=${bookingType}&endDate=${endDate}&startDate=${startDate}&hotelLocationId=${hotelLocationId}`,
        queryClient, ['bookedRooms',hotelLocationId]);
};
export const availableRoomsListQuery = async (queryClient: QueryClient, startDate:string,endDate:string,hotelLocationId:number) => {
    return await fetchData<THotelRoom[]>(`booking_reservations/get_available_rooms?startDate=${startDate}&endDate=${endDate}&hotelLocationId=${hotelLocationId}`,
        queryClient, ['availableRooms',hotelLocationId]);
};
export const getDailySummaryQuery = async (queryClient: QueryClient, startDate:string,endDate:string,hotelLocationId:number) => {
    return await fetchData<TDailySummary[]>(`booking_reservations/get_daily_summary?endDate=${endDate}&startDate=${startDate}&hotelLocationId=${hotelLocationId}`,
        queryClient, ['dailySummary',hotelLocationId]);
};
export const getReceptionistSummaryQuery = async (queryClient: QueryClient, startDate:string,endDate:string,hotelLocationId:number) => {
    return await fetchData<TDailySummary[]>(`booking_reservations/get_receptionists_summary?endDate=${endDate}&startDate=${startDate}&hotelLocationId=${hotelLocationId}`,
        queryClient, ['receptionistSummary',hotelLocationId]);
};
export const getHotelLocationsSummary = async (queryClient: QueryClient, startDate:string,endDate:string,hotelId:number) => {
    return await fetchData<TDailySummary[]>(`booking_reservations/get_hotel_locations_summary?endDate=${endDate}&startDate=${startDate}&hotelId=${hotelId}`,
        queryClient, ['hotelLocationsSummary',hotelId]);
};
export const getHotelsSummary = async (queryClient: QueryClient, startDate:string,endDate:string,hotelId:number) => {
    return await fetchData<TDailySummary[]>(`booking_reservations/get_hotel_summary?endDate=${endDate}&startDate=${startDate}&hotelId=${hotelId}`,
        queryClient, ['hotelSummary',hotelId]);
};
export const getGuestCompanyList = async (queryClient: QueryClient, hotelLocationId:number) => {
    return await fetchData<{guestCompany:string}[]>(`booking_reservations/get_guest_company?hotelLocation=${hotelLocationId}`,
        queryClient, ['guestCompany',hotelLocationId]);
};
export const getCompaniesPayable = async (queryClient: QueryClient, hotelLocationId:number,startDate:string,endDate:string) => {
    return await fetchData<TCompanyAndPayments[]>(`booking_reservations/get_companies_payable?hotelLocation=${hotelLocationId}&startDate=${startDate}&endDate=${endDate}`,
        queryClient, ['companiesPayable',hotelLocationId]);
};
export const hotelRoomStatusQuery = async (queryClient: QueryClient,queryDate:string,hotelLocationId:number) => {
    return await fetchData<THotelRoomStatus[]>(`hotel_rooms/get_room_status?startDate=${queryDate}&endDate=${queryDate}&hotelLocationId=${hotelLocationId}&hotelLocationId2=${hotelLocationId}`,
        queryClient, ['roomStatus',hotelLocationId],5000);
};
