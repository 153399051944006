import React, { useEffect, useReducer, useRef } from 'react';
import {
    changeDateFormat, displayMessage, getBookingStatus, getBookingTypes, getInvoiceNumber, getStateData, getTableRowId, isAppOnline,
    pageDataValidation, paymentsMethods, REDUCER_ACTION_TYPE, remakeDropdownSelects, StateReducerHandler
} from '../../utilities/utilFunctions';
import { TGuestAndReservationsState, TReservations } from '../../utilities/types';
import { GuestReservationContext } from '../contextProviders/GuestReservationContextProvider';
import Reservations from './Reservations';
import { Button } from 'primereact/button';
import useUserData from '../../hooks/customHooks';
import { getGuestCompanyList, hotelLocationGuestsListQuery, hotelLocationReservationsListQuery, hotelLocationRoomsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import { validateReservation } from '../../utilities/joiFunctions/joiFunctions';
import { GeneralPageProps, Loader, ReservationMoreDetails } from '../../utilities/components/UtilityComponents';
import BookingReservation from '../../utilities/classObjects/BookingReservation';
import Joi from 'joi';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { Dialog } from 'primereact/dialog';
import ReservationsList from '../DataTables/ReservationsList';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import Settings from '../../utilities/classObjects/Settings';
import HotelRoom from '../../utilities/classObjects/HotelRoom';
import { differenceInDays } from 'date-fns/differenceInDays';
import AcceptPayment from './AcceptPayment';
import { MenuItem } from 'primereact/menuitem';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { format } from 'date-fns/format';
import POSPrinter from '../POSPrinter/POSPrinter';
import { useReactToPrint } from 'react-to-print';

const INITIAL_STATE: TGuestAndReservationsState = {
    paymentMode: 'Cash',
    appState: 'onLine',
    toolBarVisible: true,
    guests: [],
    selectedGuest: { name: '', code: 0 },
    rooms: [],
    selectedRoom: { name: '', code: 0 },
    checkInDate: changeDateFormat(new Date()),
    checkOutDate: changeDateFormat(new Date()),
    checkOutDateDisplay: new Date(),
    checkInDateDisplay: new Date(),
    numberOfAdults: 0,
    numberOfChildren: 0,
    bookingType: '',
    bookingTypes: [],
    selectedBookingType: { name: '', code: 0 },
    purposeOfVisit: '',
    roomId: 0,
    guestId: 0,
    showDialogBox: false,
    reservationsList: [],
    crudeType: 'save',
    hotelId: 0,
    hotelLocationId: 0,
    roomRate: 0,
    numberOfDays: 0,
    status: '',
    modifiedBy: 0,
    showReservationPaymentDialog: false,
    financedBy: '',
    financedByList: [],
    selectedFinancier: { name: '', code: '' },
    paymentMethods: remakeDropdownSelects(paymentsMethods, 'name', 'name'),
    selectedPaymentMethod: { name: 'Cash', code: 'Cash' },
    amount: 0,
    paymentChange: 0,
    paymentId: 0,
    reservationId:0,
    bookStatusList:[],
    selectedBookingStatus:{name:'',code:''},
    printableReservation:undefined,
    guestCompany:'',
    guestCompanyList:[],
    placeOfStay:''
};
const validateGuest = Joi.object({
    guestId: Joi.number().min(1).messages({ 'number.min': 'Select a guest for your booking' })
});
const reservation = new BookingReservation();
const settings = new Settings();
const room = new HotelRoom();
const ReservationsMain = () => {
    const [state, dispatch] = useReducer(StateReducerHandler<TGuestAndReservationsState>, INITIAL_STATE);
    const [userData] = useUserData();
    const queryClient = useQueryClient();
    const toastRef = useRef(null);
    const componentRef=useRef(null);
    const reactToPrintFn=useReactToPrint({contentRef:componentRef!,ignoreGlobalStyles:true});

    useEffect(() => {
        const initReservation = async () => {
            const appSettings = await settings.getLocationSettings();
            const guestsList = isAppOnline(appSettings.connectivityState) ? await hotelLocationGuestsListQuery(queryClient, userData.hotelLocationId) : await reservation.getOfflineGuestsList(userData.hotelLocationId);
            const roomsList = isAppOnline(appSettings.connectivityState) ? await hotelLocationRoomsListQuery(queryClient, userData.hotelLocationId) : await room.getOfflineHotelRooms(userData.hotelLocationId);
            const guestsCompany=isAppOnline(appSettings.connectivityState)?await getGuestCompanyList(queryClient, userData.hotelLocationId):await reservation.getOfflineGuestCompanyList(userData.hotelLocationId);
            setStateValues({
                roomsList,
                rooms: remakeDropdownSelects(roomsList!, 'roomNumber', 'roomId'),
                bookingTypes: remakeDropdownSelects(getBookingTypes, 'name', 'code'),
                guests: remakeDropdownSelects(guestsList!.map(guest=>{return {...guest,guestName: `${guest.guestName} - ${guest.phoneNumber}`}}), 'guestName', 'guestId'),
                appState: appSettings.connectivityState,
                hotelId: userData.hotelId,
                hotelLocationId: userData.hotelLocationId,
                modifiedBy: userData.staffId,
                financedByList: remakeDropdownSelects([{ name: 'Individual', code: 'Individual' }, { name: 'Company', code: 'Company' }], 'name', 'name'),
                bookStatusList:remakeDropdownSelects(getBookingStatus,'name','name'),
                guestCompanyList:guestsCompany!.length>0?guestsCompany?.map((guestsCompany:any)=>guestsCompany.guestCompany):[],
                guestsList:guestsList
            });
        };
        initReservation().catch(console.error);
    }, []);

    const setStateValues = (stateValues: Partial<TGuestAndReservationsState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const queryReservationsList=async ()=>{
        return isAppOnline(state.appState) ? await hotelLocationReservationsListQuery(queryClient, userData.hotelLocationId)
            : await reservation.getOfflineReservationsList(userData.hotelLocationId);
    }
    const getReservationDetails = (): TReservations => {
        return getStateData<TReservations>(['guestId', 'roomId', 'checkOutDate', 'checkInDate', 'numberOfAdults', 'numberOfChildren', 'bookingType',
            'purposeOfVisit', 'status', 'reservationId', 'hotelId', 'hotelLocationId', 'modifiedBy', 'numberOfDays', 'financedBy','guestCompany'],state);
    };
    const getPayment=()=>{
        return  { paymentMode: state.paymentMode, amount: state.amount, paymentChange: state.paymentChange, paymentId: state.paymentId, paymentDate: changeDateFormat(new Date()), paymentType: 'booking' };
    }
    const saveReservation = async () => {
        try {
            const reservationDetails = getReservationDetails();
            if (!pageDataValidation(validateGuest.concat(validateReservation), reservationDetails, toastRef)) return;
            const extendedReservation = {
                ...reservationDetails, status: state.status, roomRate: state.roomRate,
                numberOfDays: state.numberOfDays, roomNumber: state.selectedRoom?.name, roomTypeDesc: state.roomTypeDesc,
                guestName: state.selectedGuest?.name,dateCreated:changeDateFormat(new Date())
            };
            const paymentData=getPayment();

            setStateValues({ isLoading: true });
            const savedReservationResults = isAppOnline(state.appState) ? await reservation.addNewReservation(reservationDetails, state.crudeType!, paymentData)
                : await reservation.addLocalReservation(extendedReservation, state.crudeType!, paymentData);
            if (savedReservationResults.status === 200) {

                state.crudeType === 'save' ? await addRecordToCache<TReservations>(queryClient, ['hotelReservations', userData.hotelLocationId], savedReservationResults.operatedData!)
                    : await updateCacheRecord<TReservations>(queryClient, ['hotelReservations', userData.hotelLocationId], [savedReservationResults.operatedData!, state.reservationId, 'reservationId']);
                displayMessage({
                    header: 'Success',
                    message: 'Reservation Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                setStateValues({printableReservation:savedReservationResults.operatedData!,invoiceNumber:getInvoiceNumber(userData.locationName!,state.reservationsList?.length!)});
                setTimeout(()=>{
                    reactToPrintFn();
                },1000);
                setTimeout(()=>{
                    resetReservation();
                },1500);

            }
        } catch (error) {
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const setupReservationsEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const selectedReservation = state.reservationsList!.find((reservation) => reservation.reservationId === parseInt(getTableRowId(e, 'id')));

            const selectedGuest=state.guestsList?.find(guest=>guest.guestId===selectedReservation?.guestId);

            if (selectedReservation) {
                const {
                    reservationId, guestId, roomId, checkInDate, checkOutDate, numberOfAdults, numberOfChildren, status,guestCompany,
                    purposeOfVisit, bookingType, hotelId, hotelLocationId, guestName, roomNumber, roomRate, financedBy,paymentChange,
                } = selectedReservation;
                setStateValues({
                    reservationId,
                    guestId,
                    roomId,
                    checkInDate: format(new Date(checkInDate!), 'yyyy-MM-dd HH:mm'),
                    checkOutDate: format(new Date(checkOutDate!),'yyyy-MM-dd HH:mm'),
                    checkOutDateDisplay: new Date(checkOutDate!),
                    checkInDateDisplay: new Date(checkInDate!),
                    numberOfAdults,
                    numberOfChildren,
                    status,
                    purposeOfVisit,
                    bookingType,
                    hotelId,
                    hotelLocationId,
                    roomRate,
                    numberOfDays: differenceInDays(new Date(checkOutDate!), new Date(checkInDate!)),
                    selectedGuest: { name: `${guestName!} - ${selectedGuest?.phoneNumber}`, code: guestId! },
                    selectedRoom: { name: roomNumber!, code: roomId! },
                    selectedBookingType: { name: bookingType!, code: bookingType! },
                    financedBy: financedBy,
                    selectedFinancier: { name: financedBy!, code: financedBy! },
                    selectedBookingStatus:{name:status!,code:status!},
                    showDialogBox: false,
                    crudeType: 'update',
                    paymentChange,
                    guestCompany,
                    printableReservation:selectedReservation
                });
            }
        } catch (error) {
            throw error;
        }
    };
    const resetReservation = () => {
        setStateValues({
            reservationId: 0,
            guestId: 0,
            roomId: 0,
            checkInDate: changeDateFormat(new Date()),
            checkOutDate: changeDateFormat(new Date()),
            checkOutDateDisplay: new Date(),
            checkInDateDisplay: new Date(),
            numberOfAdults: 0,
            numberOfChildren: 0,
            status: '',
            purposeOfVisit: '',
            bookingType: '',
            hotelId: 0,
            hotelLocationId: 0,
            selectedGuest: { name: '', code: '' },
            selectedRoom: { name: '', code: 0 },
            selectedBookingType: { name: '', code: '' },
            showDialogBox: false,
            crudeType: 'save',
            numberOfDays: 0,
            roomRate: 0,
            financedBy: '',
            selectedFinancier: { name: '', code: '' },
            paymentChange: 0,
            amount: 0,
            selectedBookingStatus:{name:'',code:''},
            printableReservation:undefined,
            guestCompany:'',
        });
    };
    const onDialogShow = async () => {
        try {
            setStateValues({ isLoading: true });
            const reservationsList = await queryReservationsList();
            setStateValues({ reservationsList });
            document.getElementById('simpleTable')?.addEventListener('click',(e)=>{
                const target = e.target as HTMLElement;
                if (target.tagName === 'BUTTON') {
                    const closestTr=target?.closest('tr');
                    if(closestTr){
                        const cellIndex = Array.from(target?.closest('tr')?.children!).indexOf(target?.closest('td')!);
                        if (cellIndex === 8) {
                            const reservationId=parseInt(target?.closest('div')?.id!);
                            setStateValues({reservationId: reservationId});
                        }
                    }
                }
            });
        } catch (error) {
            throw error;
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const showReservationPayment = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedReservation = state.reservationsList?.find(reservation => reservation.reservationId === parseInt((e.target as HTMLElement)?.closest('button')?.getAttribute('data-reservation-id')!));

        setStateValues({ showReservationPaymentDialog: true, selectedReservation });
    };

    const toggleBookingReservationStatus=(event:any)=>{
        setStateValues({status:event.item.label});
        confirmUpdate(event.item.label);
    }
    const confirmUpdate = (currentStatus:string) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            position:'bottom',
            accept:async ()=>{
                try{
                    setStateValues({isLoading:true});
                    const updateResults= isAppOnline(state.appState)?await reservation.updateReservationStatus({reservationId:state.reservationId,status:currentStatus,modifiedBy:state.modifiedBy})
                        :await reservation.updateReservationStatusLocal({reservationId:state.reservationId,status:currentStatus,modifiedBy:state.modifiedBy});
                    await updateCacheRecord<TReservations>(queryClient, ['hotelReservations', userData.hotelLocationId], [updateResults.operatedData!, state.reservationId, 'reservationId']);

                    setStateValues({reservationsList:await queryReservationsList()});
                }catch(error){
                    throw error;
                }finally {
                    setStateValues({isLoading:false});
                }
            },
            reject:()=>{}
        });
    };

    const bookingStatusItems:MenuItem[]=[
        {label:'Pending',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Confirmed',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Cancelled',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'No-Show',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Checked-in',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Checked-out',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'In Progress',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Expired',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Waitlisted',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Rebooked',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Prepaid',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
        {label:'Refunded',icon:'pi pi-refresh',command:toggleBookingReservationStatus},
    ]
    return (
        <>
            {state.isLoading && <Loader />}
            <ConfirmDialog />
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar start={<LeftToolBar firstButton={{ title: 'List', icon: 'pi pi-list', onClickEvent: () => setStateValues({ showDialogBox: true }) }} />} end={<RightToolBar />}></Toolbar>
            </div>
            <GuestReservationContext.Provider value={{ state, setStateFunction: setStateValues }}>
                <Reservations />
            </GuestReservationContext.Provider>
            <div className="field">
                <Button onClick={saveReservation}>{state.crudeType === 'save' ? 'Save Reservation' : 'Update Reservation'}</Button>
            </div>
            <Dialog onHide={() => setStateValues({ showDialogBox: false })} visible={state.showDialogBox} position={'top'} onShow={onDialogShow} header="Reservations/Bookings List">
                <ReservationsList tableData={state.reservationsList!} setupTableDataEdit={setupReservationsEdit} promptTableDataDelete={() => {
                }}
                                  showReservationDialog={showReservationPayment} bookingStatusItems={bookingStatusItems} />
            </Dialog>
            <Dialog onHide={() => setStateValues({ showReservationPaymentDialog: false })} visible={state.showReservationPaymentDialog} className="w-12" position={'top'}
                    header={`${state.selectedReservation?.guestName}'s Reservation Payment Details`}>
                <ReservationMoreDetails panelInfo={state.selectedReservation!} />
                <AcceptPayment reservationDetails={state.selectedReservation!} />
            </Dialog>
            {<div className="">
                <POSPrinter
                    reservationDetails={state.printableReservation!}
                    ref={componentRef}
                    userData={userData}
                    amountPaid={state.amount!}
                    invoiceNumber={state.invoiceNumber!}
                />
            </div>}
        </>
    );
};
export default ReservationsMain;
