import { DropdownOption, PromptUserActionProps, StateReducerAction, TChart, TExpenditure, TItem, TLocationSettings, TPayment, TReservations, TRoomsDistribution, TUser } from './types';
import { confirmPopup } from 'primereact/confirmpopup';
import Joi from 'joi';
import { DMProps } from './types';
import { format } from 'date-fns/format';
import React from 'react';
import { getCode, getNames } from 'country-list';
import { addMonths, parseISO } from 'date-fns';
const _=require('lodash');

export const enum REDUCER_ACTION_TYPE {
    CHANGE_STATE_VALUES
}
export const displayMessage = ({ header, message, infoType, toastComponent, life, stickyStatus = false, allowClose = false }: DMProps) => {
    toastComponent.current?.show({
        severity: infoType,
        summary: header,
        detail: message,
        life,
        sticky: stickyStatus,
        closable: allowClose
    });
};

export const pageDataValidation = <T>(validationObject: Joi.ObjectSchema<T>, stateValues: Partial<T>, toastRef: React.MutableRefObject<null>): boolean => {
    const value = validationObject.validate(stateValues, { abortEarly: true });
    if (value.error) {
        console.log(value);
        displayMessage({
            toastComponent: toastRef,
            header: 'Error',
            message: value.error.details[0].message,
            infoType: 'error',
            life: 3000
        });
        return false;
    }
    return true;
};
export function StateReducerHandler<T>(state: T, action: StateReducerAction): T {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES:
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
}
export function extractStateValues<T>(selectableProperties: Array<keyof T>, parentObject: T) {
    const selectedValues: any = {};
    for (const prop of selectableProperties) {
        selectedValues[prop] = parentObject[prop];
    }
    return selectedValues;
}
export const changeDateFormat = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
};

export const getTableRowId = (e: React.MouseEvent<HTMLButtonElement>, rowProp: string) => {
    //@ts-ignore
    if ((e.target as HTMLElement).closest('button')[rowProp]) {
        //@ts-ignore
        return e.target.closest('button')[rowProp]!;
    }
    return 'Not Found';
};
export const getBaseURL=()=>{
    return `https://datastore.harmonyhotels.biz`;
    // return 'http://localhost:5000';
}
export const getStateData = <T>(props: Array<keyof T>, state: T):T => {
    return extractStateValues<T>(props, state);
};

export const remakeDropdownSelects = <T>(data: T[], nameOption: string, idOption: string | number): DropdownOption[] => {
    return data.map((selectData: any) => {
        const nameDisplay = selectData[nameOption];
        return {
            name: nameDisplay,
            code: selectData[idOption]
        };
    });
};
export const onInputControlFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    switch (e.type) {
        case 'focus':
            if (e.target.value === '0') {
                e.target.value = '';
                e.target.select();
            }
            break;
        case 'blur':
            if (e.target.value === '') {
                e.target.value = '0';
            }
            break;
    }
};


export const promptUserAction = ({ yesAction = () => {}, noAction = () => {}, event,
                                     displayText, yesActionDisplay = 'Yes', noActionDisplay = 'No', textSize = 14 }: PromptUserActionProps) => {
    confirmPopup({
        target: event.currentTarget,
        message: displayText,
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-success',
        rejectClassName: 'p-button-danger',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        accept: yesAction,
        reject: noAction,
        acceptLabel: yesActionDisplay,
        rejectLabel: noActionDisplay,
        style: { width: '400px', fontSize: textSize }
    });
};
export const truncateString = (str: string, slashAt: number) => {
    return str.length > slashAt ? str.slice(0, slashAt) + '...' : str;
};

export const getURLFileName = (videoURL: string) => {
    const splitFileName = videoURL.split('/');
    return splitFileName[splitFileName.length - 1];
};
export const isValidHttpsUrl = (url: string) => {
    const regex = /^https:\/\/[^\s/$.?#].[^\s]*$/;
    return regex.test(url);
};
export const getCurrentUser = async (): Promise<TUser> => {
    const currentUser = localStorage.getItem('userData');
    return currentUser !== null ? JSON.parse(currentUser) : undefined;
};
export const numberToLetter = (num: number): string => {
    if (num >= 1 && num <= 5) {
        return String.fromCharCode(64 + num); // ASCII value of 'A' is 65
    } else {
        return 'Invalid input, please enter a number between 1 and 5.';
    }
};
export const updateCacheItem = async <T>(updatedItem: T, itemBeforeUpdate: T, allItems: T[]) => {
    const indexOfItem = _.findIndex(allItems, itemBeforeUpdate);

    const newItems: T[] = [...allItems];

    _.set(newItems, indexOfItem, updatedItem);

    return newItems;
};

export async function deleteCacheItem<T>(indexOfItem: number, allItems: T[]) {
    const clonedItems = [...allItems];

    _.pullAt(clonedItems, indexOfItem);

    return clonedItems;
}
export const roomAmenities=[
    'King Size Bed',
    'Queen Size Bed',
    'Twin Bed',
    'Air condition',
    'TV',
    'Wi-Fi Access',
    'Wardrobe or Closet',
    'Desk and Chair',
    'Telephone',
    'Room Service',
    'Towels',
    'Shampoo',
    'Soap and Lotion',
    'Hair Dryer',
    'Shower or Bathtub',
    'Bathrobe and Slippers',
    'Mini Fridge',
    'Coffee Maker or Kettle',
    'Safe',
    'Iron and Ironing Board',
    'Complimentary bottled water',
    'Alarm Clock',
    'Charging Ports or Outlets',
    'Hangers',
    'Full-length Mirror',
    'Blackout Curtains',
    'Personal Concierge Service',
    'Mini Bar',
    'Jacuzzi or Rain Showers'
];
export const getNationalIdTypes=[
    {name:'Ghana Card', code:'Ghana Card'},
    {name:'Voters ID', code:'Voters ID'},
    {name:'Passport', code:'Passport'},
    {name:'NHIS', code:'NHIS'},
]
export const getBookingTypes=[
    {name:'Booking',code:'Booking'},
    {name:'Reservation',code:'Reservation'},
]
export const getGenders=[
    {name:'Male',code:'Male'},
    {name:'Female',code:'Female'},
];
export const getUserRoles=[
    {name:'Admin',code:'Admin'},
    {name:'User',code:'User'},
    {name:'Manager',code:'Manager'},
];
export const paymentsMethods=[{name:'MOMO'},{name:'Cash'},{name:'Credit Card'},{name:'POS'}]
export const getBookingStatus=[
    {name:'Pending',code:'Pending'},
    {name:'Confirmed',code:'Confirmed'},
    {name:'Cancelled',code:'Cancelled'},
    {name:'No-Show',code:'No-Show'},
    {name:'Checked-In',code:'Checked-In'},
    {name:'Checked-Out',code:'Checked-Out'},
    // should add this to others buyers
    // {name:'In Progress',code:'In Progress'},
    // {name:'Expired',code:'Expired'},
    // {name:'Waitlisted',code:'Waitlisted'},
    // {name:'Rebooked',code:'Rebooked'},
    // {name:'Prepaid',code:'Prepaid'},
    // {name:'Refunded',code:'Refunded'},
]
export const staffDepartments=[{name:'Administrator'},{name:'Receptionist'},{name:'Chef'},{name:'Cleaner'},{name:'Waiter'},{name:'Waitress'}]
export const displayCountry = (): DropdownOption[] => {
    const countryList = getNames().map((country: string) => {
        return { countryDesc: country, countryId: getCode(country) };
    });
    return remakeDropdownSelects(countryList, 'countryDesc', 'countryId');
};
export const defaultSettings=():TLocationSettings=>{
    return {connectivityState: 'onLine'}
}
export const isAppOnline = (connectivityState: string) => {
    return connectivityState === 'onLine';
};
export const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
export const makeSelectableItemList=(itemsList:TItem[])=>{
    return remakeDropdownSelects(itemsList!, 'itemName', 'itemId')
}

export const objectExistsInArray=<T>(array:T[],comparingObject:T,comparingProp:keyof T):boolean=>{
    return _.some(array,(item: T)=>item[comparingProp]===comparingObject[comparingProp])
}
export const getTotalPaid=(paymentsList:TPayment[])=>{
    if(paymentsList.length>0){
        return paymentsList.reduce((previousValue:number,currentValue:TPayment)=>{
            return previousValue+currentValue.amount;
        },0)
    }
    return 0;
}
export const straightLine = (hStartFrom = 0, hEndTo = 540, vStartFrom = 1, vEndTo = 1, lineWidth = 0) => {
    return {
        canvas: [
            {
                type: 'line',
                x1: hStartFrom,
                y1: vStartFrom,
                x2: hEndTo,
                y2: vEndTo,
                lineWidth: lineWidth,
                color: 'rgba(23,22,22,0.95)'
            }
        ]
    };
};
export const getMonthlyCountSales=(selectedYear:number,reservations:TReservations[])=>{
    try{
        const currentYearReservations = reservations.filter((reservation) => parseISO(reservation.dateCreated as string).getFullYear() == selectedYear);
        const firstMonthOfYear = new Date(`${selectedYear}-01-01T00:00:00.000Z`);
        const lastMonthOfYear = new Date(`${selectedYear+ 1}-01-01T00:00:00.000Z`);
        const monthlyCounts = [];
        let currentMonth = firstMonthOfYear;

        while (currentMonth < lastMonthOfYear) {
            const thisMonth = format(currentMonth, 'yyyy-MM');
            const reservationsThisMonth = currentYearReservations.filter((reservation) => format(parseISO(reservation.dateCreated as string), 'yyyy-MM') === thisMonth);
            monthlyCounts.push(reservationsThisMonth.length);
            currentMonth = addMonths(currentMonth, 1);
        }
        return monthlyCounts;
    }catch(error:any){
        throw error;
    }
}
export const graphChart = (monthlyReservations:number[],monthlyBookings: number[]): TChart => {
    return {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Reservations',
                data: monthlyReservations,
                backgroundColor: '#fcf461',
                fill: true,
                barPercentage: 1
            },
            {
                label: 'Bookings',
                data: monthlyBookings,
                backgroundColor: '#6661fc',
                fill: true,
                barPercentage: 1
            }
        ]
    };
};

export const getBarChartOptions=(maxValue:number)=>{
    return  {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                position: 'top',
                align: 'end'
            }
        },
        responsive: true,
        hover: {
            mode: 'index'
        },
        scales: {
            y: {
                min: 0,
                max: maxValue,
                grid: {
                    display: false
                }
            },
            x: {
                grid: {
                    color: '#61c1fc',
                    drawBorder: false,
                    display:false
                }
            }
        }
    };
}

export const getAccumulatedPayment=(selectedYear:number,payments:TPayment[])=>{
    try{
        const currentYearPayments = payments.filter((payment) => parseISO(payment.dateCreated as string).getFullYear() == selectedYear);
        const firstMonthOfYear = new Date(`${selectedYear}-01-01T00:00:00.000Z`);
        const lastMonthOfYear = new Date(`${selectedYear+ 1}-01-01T00:00:00.000Z`);
        const monthlySales = [];
        let currentMonth = firstMonthOfYear;

        while (currentMonth < lastMonthOfYear) {
            const thisMonth = format(currentMonth, 'yyyy-MM');
            const paymentsThisMonth = currentYearPayments.filter((payment) => format(parseISO(payment.dateCreated as string), 'yyyy-MM') === thisMonth).reduce((previousValue:number,currentValue)=>{
                return previousValue+currentValue.amount
            },0);
            monthlySales.push(paymentsThisMonth);
            currentMonth = addMonths(currentMonth, 1);
        }
        return monthlySales;
    }catch(error:any){
        throw error;
    }

}

export const getAccumulatedMonthlyExpenditures=(selectedYear:number,expenditures:TExpenditure[])=>{
    try{
        const currentYearExpenses = expenditures.filter((transaction) => parseISO(transaction.dateCreated as string).getFullYear() == selectedYear);
        const firstMonthOfYear = new Date(`${selectedYear}-01-01T00:00:00.000Z`);
        const lastMonthOfYear = new Date(`${selectedYear+ 1}-01-01T00:00:00.000Z`);
        const monthlyExpenditure = [];
        let currentMonth = firstMonthOfYear;

        while (currentMonth < lastMonthOfYear) {
            const thisMonth = format(currentMonth, 'yyyy-MM');
            const expensesThisMonth = currentYearExpenses.filter((expense) => format(parseISO(expense.dateCreated as string), 'yyyy-MM') === thisMonth).reduce((previousValue:number,currentValue)=>{
                return previousValue+currentValue.expenditureAmount
            },0);
            monthlyExpenditure.push(expensesThisMonth);
            currentMonth = addMonths(currentMonth, 1);
        }
        return monthlyExpenditure;
    }catch(error:any){
        throw error;
    }

}

export const getAccumulatedRevenue=(paymentsArr:number[],expensesArray:number[])=>{
    let result = [];

    // Calculate the positional subtraction
    for (let i = 0; i < paymentsArr.length; i++) {
        result.push(paymentsArr[i] - expensesArray[i]);
    }
    return result;
}

export const getRevenueChart=(chartPayments:number[],chartExpenses:number[],chartRevenue:number[])=>{
    return  {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Payments',
                data: chartPayments,
                borderColor: '#EEE500',
                pointBackgroundColor: '#EEE500',
                backgroundColor: 'rgba(238, 229, 0, 0.05)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Expenses',
                data: chartExpenses,
                borderColor: '#FC6161',
                pointBackgroundColor: '#FC6161',
                backgroundColor: 'rgba(253, 72, 74, 0.05)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Revenue',
                data: chartRevenue,
                borderColor: '#00D0DE',
                pointBackgroundColor: '#00D0DE',
                backgroundColor: 'rgba(0, 208, 222, 0.05)',
                fill: true,
                tension: 0.4
            }
        ]
    };
}
export const getRoomsDistributionsChart=(roomsData:TRoomsDistribution[]):TChart=>{
    const colors=roomsData.map((room)=>room.customColor);
    return {
        labels: roomsData.map((room)=>room.roomNumber),
        datasets: [
            {
                data: roomsData.map((room)=>room.bookedCount),
                backgroundColor: colors,
                hoverBackgroundColor: colors,
                borderColor: 'transparent',
                fill: true,
                barPercentage: 0
            }
        ]
    }
}
export const getRandomHexDecimalColor=()=> {
    // Generate a random number between 0 and 16777215 (which is FFFFFF in hexadecimal)
    const color = Math.floor(Math.random() * 16777215).toString(16);
    // Pad the color with zeros if it's shorter than 6 characters
    return '#' + ('000000' + color).slice(-6);
}
export const getInvoiceNumber = (uniqueString: string, transactionsToday: number): string => {
    const now = new Date();
    const dateStr = now.toISOString().slice(0, 10).replace(/-/g, '');
    const initials = uniqueString
        .split(' ')
        .map((str) => str[0])
        .join('');
    return `${initials}${dateStr}000${transactionsToday}`;
};
export const getTodayAtNoon=(inputDate:Date)=> {
    if (!(inputDate instanceof Date)) {
        throw new Error("Input must be a Date object.");
      }

      // Create a new Date object to avoid modifying the original
      const noonDate = new Date(inputDate);
      noonDate.setHours(12, 0, 0, 0); // Set to 12:00:00.000
      return noonDate;
  }
