import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useReducer, useRef } from 'react';
import {
    defaultSettings, displayMessage, getStateData, getTableRowId, isAppOnline, onInputControlFocus, pageDataValidation,
    REDUCER_ACTION_TYPE, remakeDropdownSelects, roomAmenities, StateReducerHandler
} from '../../utilities/utilFunctions';
import { DropdownOption, TAppState, TCrudType, THotelRoom, TLocationSettings } from '../../utilities/types';
import { InputNumber } from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { hotelLocationRoomsTypeQuery, hotelLocationsListQuery, hotelRoomsListQuery, hotelsListQuery } from '../../utilities/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import useUserData from '../../hooks/customHooks';
import { validateHotelRoom } from '../../utilities/joiFunctions/joiFunctions';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import HotelRoomClass from '../../utilities/classObjects/HotelRoom';
import { Toolbar } from 'primereact/toolbar';
import { LeftToolBar, RightToolBar } from '../pageToolBars/PagsToolBars';
import { Dialog } from 'primereact/dialog';
import HotelRoomsList from '../DataTables/HotelRoomsList';
import { addRecordToCache, updateCacheRecord } from '../../utilities/reactQueryFunctions';
import Settings from '../../utilities/classObjects/Settings';
import Hotel from '../../utilities/classObjects/Hotel';
import HotelLocation from '../../utilities/classObjects/HotelLocation';
import { InputText } from 'primereact/inputtext';
import RoomTypes from '../../utilities/classObjects/RoomTypes';
import RoomType from './RoomType';

const _=require('lodash');
type THotelRoomState = THotelRoom & {
    hotels: DropdownOption[];
    hotelLocations: DropdownOption[];
    selectedHotel: DropdownOption;
    selectedHotelLocation: DropdownOption;
    roomTypes: DropdownOption[];
    selectedRoomType: DropdownOption;
    isLoading:boolean,
    showDialogBox:boolean,
    roomsList:THotelRoom[],
    crudType:TCrudType,
    appState:TAppState,
    settings:TLocationSettings,
    showRoomTypeDialogBox:boolean,
    bookedRooms:THotelRoom[]
};
const INITIAL_STATE: THotelRoomState = {
    amenities: [],
    capacity: 0,
    description: '',
    hotelId: 0,
    locationId: 0,
    roomId: 0,
    roomNumber: 0,
    roomRate: 0,
    roomTypeId: 0,
    hotelLocations: [],
    hotels: [],
    selectedHotel: { name: '', code: 0 },
    selectedHotelLocation: { name: '', code: 0 },
    roomTypes: [],
    selectedRoomType: { name: '', code: 0 },
    isLoading:false,
    showDialogBox:false,
    roomsList:[],
    crudType:'save',
    appState:'onLine',
    settings:defaultSettings(),
    modifiedBy:0,
    showRoomTypeDialogBox:false,
    bookedRooms:[]
};
const hotelRoomObject=new HotelRoomClass();
const settings=new Settings();
const hotel=new Hotel();
const locationObject=new HotelLocation();
const roomTypeObject=new RoomTypes();
const HotelRoom = () => {
    const queryClient=useQueryClient();
    const [state, dispatch] = useReducer(StateReducerHandler<THotelRoomState>, INITIAL_STATE);
    const OPRef = useRef<OverlayPanel>(null);
    const [userData]=useUserData();
    const toastRef=useRef(null);

    useEffect(()=>{
        const initHotelRoom=async ()=>{
            const appSettings= await settings.getLocationSettings();
            const hotelsList=isAppOnline(appSettings.connectivityState)?await hotelsListQuery(queryClient,userData.staffId):
                await hotel.getOffLineHotelsList(userData.staffId);
            setStateValues({
                hotels:remakeDropdownSelects(hotelsList!,'hotelName','hotelId'),
                appState:appSettings.connectivityState,
                modifiedBy:userData.staffId,
            });
        }
        initHotelRoom().catch(console.error);
    },[]);
    const setStateValues = (stateValues: Partial<THotelRoomState>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    const queryRoomTypes=async (hotelLocationId:number)=>{
        return isAppOnline(state.appState)?await hotelLocationRoomsTypeQuery(queryClient,hotelLocationId):await roomTypeObject.getOfflineRoomTypes(hotelLocationId);
    }
    const getHotelRoomDetails=()=>{
        return getStateData<THotelRoom>(['roomId','hotelId','locationId','roomTypeId','roomNumber','roomRate','amenities','capacity','description','modifiedBy'],state);
    }
    const onRoomAmenityAdd = (e: CheckboxChangeEvent) => {
        if (e.checked === true) {
            setStateValues({ amenities: [...state.amenities, e.target.value] });
        } else {
            setStateValues({ amenities: state.amenities.filter((amenity) => amenity !== e.target.value) });
        }
    };
    const onHotelChange=async (e:any)=>{
        const hotelLocations=isAppOnline(state.appState)?await hotelLocationsListQuery(queryClient,e.value.code):await locationObject.getOfflineLocations(e.value.code);
        if (hotelLocations!==undefined)
        setStateValues({hotelId:e.value.code,selectedHotel:e.value,hotelLocations:remakeDropdownSelects(hotelLocations,'locationName','hotelLocationId')});
    }
    const onHotelLocationChange=async (e:any)=>{
        const roomTypes=await queryRoomTypes(e.value.code);
        if(roomTypes!==undefined)
        setStateValues({ selectedHotelLocation: e.value,roomTypes:remakeDropdownSelects(roomTypes,'roomTypeDesc','roomTypeId'),locationId:e.value.code });
    }
    const RoomAmenitiesDisplayTemplate = (amenity: string) => {
        return (
            <div className="col-12">
                <div className="card">
                    <div className="grid">
                        <Checkbox id={amenity} onChange={onRoomAmenityAdd} checked={state.amenities.includes(amenity)} value={amenity}/>
                        <label className="ml-3">{amenity}</label>
                    </div>
                </div>
            </div>
        );
    };

    const saveRoom=async ()=>{
        try{
            const hotelRoomDetails=getHotelRoomDetails();
            if(!pageDataValidation(validateHotelRoom,hotelRoomDetails,toastRef)) return;
            setStateValues({isLoading:true});
            const hotelRoomSavedResults=isAppOnline(state.appState)?await hotelRoomObject.addNewRoom(hotelRoomDetails,state.crudType)
                :await hotelRoomObject.addNewRoomOffline({...hotelRoomDetails,locationName:state.selectedHotelLocation.name,
                    hotelName:state.selectedHotel.name,roomTypeDesc:state.selectedRoomType.name,roomNumber:`${state.roomNumber}`},state.crudType);
            if(hotelRoomSavedResults.status===200){
                state.crudType==='save'?await addRecordToCache<THotelRoom>(queryClient,['hotelRoomsList',userData.hotelLocationId],hotelRoomSavedResults.operatedData!)
                    :await updateCacheRecord<THotelRoom>(queryClient,['hotelRoomsList',userData.hotelLocationId],[hotelRoomSavedResults.operatedData!,state.roomId,'roomId']);
                displayMessage({
                    header: 'Success',
                    message: 'Room Data was successfully changed!',
                    life: 3000,
                    infoType: 'success',
                    toastComponent: toastRef
                });
                resetRoomsState();
            }
        }catch(error){

        }finally {
            setStateValues({isLoading:false});
        }
    }

    const setupHotelRoomsEdit=async (e:React.MouseEvent<HTMLButtonElement>)=>{
        const selectedHotelRoom=state.roomsList.find(room=>room.roomId===parseInt(getTableRowId(e,'id')));
        if(selectedHotelRoom){
            const {roomId,hotelId,locationId,roomNumber,roomTypeId,roomRate,amenities,capacity,description,roomTypeDesc,locationName,hotelName}=selectedHotelRoom;
            setStateValues({
                roomId,
                hotelId,
                locationId,
                roomNumber,
                roomTypeId,
                roomRate,
                amenities:typeof amenities==='string'?JSON.parse(amenities!):amenities,
                capacity,
                description,
                selectedHotel:{name:hotelName!,code:hotelId},
                selectedHotelLocation:{name:locationName!,code:locationId},
                selectedRoomType:{name:roomTypeDesc!,code:roomTypeId},
                crudType:'update',
                showDialogBox:false
            });
            await onHotelChange({value:{name:hotelName!,code:hotelId}});
            await onHotelLocationChange({value:{name:locationName,code:locationId}});
        }
    }
    const resetRoomsState=()=>{
        setStateValues({
            roomId:0,
            hotelId:0,
            locationId:0,
            roomNumber:0,
            roomTypeId:0,
            roomRate:0,
            amenities:[],
            capacity:0,
            description:'',
            selectedHotel:{name:'',code:0},
            selectedHotelLocation:{name:'',code:0},
            selectedRoomType:{name:'',code:0},
            crudType:'save',
        });
    }
    const onDialogShow=async ()=>{
        try{
            const roomsList=isAppOnline(state.appState)?
                await hotelRoomsListQuery(queryClient,userData.hotelLocationId):await hotelRoomObject.getOfflineHotelRooms(userData.hotelLocationId);
            console.log(roomsList);
            setStateValues({
                roomsList:roomsList
            });
        }catch(error){

        }finally {
            setStateValues({isLoading:false});
        }
    }
    const reQueryRoomTypesList=async ()=>{
        const roomTypes=await queryRoomTypes(state.locationId);
        if(roomTypes!==undefined)
            setStateValues({ roomTypes:remakeDropdownSelects(roomTypes,'roomTypeDesc','roomTypeId')});
    }

    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12">
                <Toolbar
                    start={<LeftToolBar
                        firstButton={{title:'List',onClickEvent:() => setStateValues({ showDialogBox: true }),icon:'pi pi-list'}}
                    />}
                    end={<RightToolBar/>}></Toolbar>
            </div>
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="col-6">
                                <div className="field">
                                    <label htmlFor="countries">Hotels</label>
                                    <Dropdown value={state.selectedHotel} onChange={onHotelChange} options={state.hotels} optionLabel="name" placeholder="Select" />
                                </div>
                                <div className="field">
                                    <label htmlFor="countries">Locations</label>
                                    <Dropdown value={state.selectedHotelLocation} onChange={onHotelLocationChange} options={state.hotelLocations} optionLabel="name" placeholder="Select" />
                                </div>
                                <div className="field">
                                    <div className="grid">
                                        <div className="col-11">
                                            <label htmlFor="countries">Room Type</label>
                                            <Dropdown value={state.selectedRoomType} onChange={(e) => setStateValues({ selectedRoomType: e.value, roomTypeId: e.value.code })} options={state.roomTypes} optionLabel="name" placeholder="Select" />
                                        </div>
                                        <div className="col-1 mt-3">
                                            <Button icon="pi pi-plus" onClick={()=>setStateValues({showRoomTypeDialogBox:true})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="roomNumber">Room Number</label>
                                    <InputText id="roomNumber" value={state.roomNumber as string} onChange={(e) => setStateValues({ roomNumber: e.target.value! })} onFocus={onInputControlFocus} onBlur={onInputControlFocus}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field">
                                    <label htmlFor="roomRate">Room Rate</label>
                                    <InputNumber id="roomRate" value={state.roomRate} onChange={(e) => setStateValues({ roomRate: e.value! })} onFocus={onInputControlFocus} onBlur={onInputControlFocus}/>
                                </div>
                                <div className="field">
                                    <label>Room Amenities</label>
                                    <Button onClick={(e) => OPRef.current?.toggle(e)}>Select Room Amenities</Button>
                                </div>
                                <div className="field">
                                    <label htmlFor="capacity">Room Capacity</label>
                                    <InputNumber id="capacity" value={state.capacity} onChange={(e) => setStateValues({ capacity: e.value! })} onBlur={onInputControlFocus} onFocus={onInputControlFocus}/>
                                </div>
                                <div className="field">
                                    <label htmlFor="description">Room Description</label>
                                    <InputTextarea id="description" value={state.description} onChange={(e) => setStateValues({ description: e.target.value! })} rows={2} />
                                </div>
                                <div className="field">
                                    <Button onClick={saveRoom}>{state.crudType==='save'?'Save Room':'Update Room'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel ref={OPRef} className="w-3">
                <DataView value={roomAmenities} itemTemplate={RoomAmenitiesDisplayTemplate} paginator rows={5} />
            </OverlayPanel>
            <Dialog onHide={()=>setStateValues({showDialogBox:false})} visible={state.showDialogBox} position={'top'} onShow={onDialogShow}>
                <HotelRoomsList tableData={state.roomsList} setupTableDataEdit={setupHotelRoomsEdit} promptTableDataDelete={()=>{}}/>
            </Dialog>
            <Dialog onHide={()=>setStateValues({showRoomTypeDialogBox:false})} visible={state.showRoomTypeDialogBox} position="top" className="w-6">
                <RoomType reQueryRoomTypes={reQueryRoomTypesList}/>
            </Dialog>
        </>
    );
};
export default HotelRoom;
