import { TCrudType, THotelRoom, THotelRoomStatus } from '../types';
import axiosFetch from '../axiosConfig';
import { getBaseURL } from '../utilFunctions';
import { db } from '../../offline/db';
import { Queue } from './Queue';
import { isEqual } from 'date-fns/isEqual';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import bookingReservation from './BookingReservation';
import { hotelRoomStatusQuery } from '../reactQueryUtils';
import hotelRoomsModel from '../../../hotel_server/src/models/HotelRoomsModel';

const queue = new Queue();
const _ = require('lodash');

class HotelRoomClass {
    async addNewRoom(hotelRoom: THotelRoom, crudType: string) {

        const data = await axiosFetch<THotelRoom>('POST', `${getBaseURL()}/hotel_rooms/new_hotel_room`, { requestBody: { ...hotelRoom, crudType } });
        return { status: data.status, operatedData: data.data?.operatedData };
    }

    async addNewRoomOffline(hotelRoom: THotelRoom, crudType: TCrudType) {
        return crudType === 'save' ? await this.addNewRoomLocal(hotelRoom) : this.updateRoomLocal(hotelRoom);
    }

    async addNewRoomLocal(hotelRoom: THotelRoom) {
        let room: THotelRoom = { ...hotelRoom };
        delete room?.roomId;
        try {
            return db.transaction('rw', [db.hotelRooms, db.queuedTables], async () => {
                const roomIdNew = await db.hotelRooms.add(room);
                const newRoom = await db.hotelRooms.get(roomIdNew);
                await queue.enqueue<THotelRoom>(newRoom!, 'hotelRooms');
                return { status: 200, operatedData: newRoom };
            });
        } catch (error) {
            throw error;
        }
    }

    async updateRoomLocal(hotelRoom: THotelRoom) {
        try {
            return db.transaction('rw', [db.hotelRooms, db.queuedTables], async () => {
                const updatedRoomId = await db.hotelRooms.put(hotelRoom);
                await queue.updateQueue<THotelRoom>(hotelRoom, 'hotelRooms', 'roomId', updatedRoomId!);
                return { status: 200, operatedData: hotelRoom! };
            });
        } catch (error) {
            throw error;
        }
    }

    async getOfflineHotelRooms(hotelLocationId: number) {
        try {
            return db.hotelRooms.where({ locationId: hotelLocationId }).toArray();
        } catch (error) {
            throw error;
        }
    }

    async getOfflineRoomStatus(queryDate: string,hotelLocationId: number) {
        const bookingReservations = await db.hotelReservations.where({ hotelLocationId: hotelLocationId })
            .and((reservation) => {
                const checkInDate = new Date(reservation.checkInDate!);
                const checkOutDate = new Date(reservation.checkOutDate!);
                return (
                    (isWithinInterval(new Date(queryDate),{start:checkInDate,end:checkOutDate}))
                );
            })
            .toArray();
        const hotelRooms = await db.hotelRooms.where({ locationId: hotelLocationId }).toArray();

        const roomStatus:THotelRoomStatus[] = hotelRooms.map((hotelRoom)=>{
            const matchingRoom = bookingReservations.find(bookingReservation=>bookingReservation.roomId===hotelRoom.roomId);
            if(matchingRoom){
                return {
                    roomId:matchingRoom.roomId,
                    roomNumber:hotelRoom.roomNumber,
                    description:hotelRoom.description,
                    roomStatus:matchingRoom.status,
                    checkOutDate:matchingRoom.checkOutDate,
                    checkInDate:matchingRoom.checkInDate,
                    bookingType:matchingRoom.bookingType,
                    purposeOfVisit:matchingRoom.purposeOfVisit
                }
            }else{
                return {
                    roomId:hotelRoom.roomId,
                    roomNumber:hotelRoom.roomNumber,
                    description:hotelRoom.description,
                    roomStatus:'Available',
                    checkOutDate:'',
                    checkInDate:'',
                    bookingType:'',
                    purposeOfVisit:''
                }
            }
        })

        return roomStatus;
    }

    async getOfflineBookedRooms(hotelLocationId: number, beginDate: string, endDate: string,bookingType:string) {
        try {
            const hotelRooms = await db.hotelRooms.where({ locationId: hotelLocationId }).toArray();
            const bookingReservations = await db.hotelReservations.where({ hotelLocationId: hotelLocationId, bookingType: bookingType })
                .and((reservation) => {
                    const checkInDate = new Date(reservation.checkInDate!);
                    const checkOutDate = new Date(reservation.checkOutDate!);
                    return (isBefore(checkInDate, new Date(endDate)) || isEqual(checkInDate, new Date(endDate))) && (isAfter(checkOutDate, new Date(beginDate)) || isEqual(checkOutDate, new Date(beginDate)));
                }).toArray();
            const occupiedRooms = [];
            if (hotelRooms !== undefined) {
                for (const reservation of bookingReservations) {
                    if (_.some(hotelRooms, (hotelRoom: THotelRoom) => hotelRoom.roomId === reservation.roomId)) {
                        occupiedRooms.push(reservation);
                    }
                }

            }
            return occupiedRooms;
        } catch (error) {
            throw error;
        }
    }

    async getOfflineAvailableRooms(hotelLocationId: number, beginDate: string, endDate: string) {
        try {
            // Fetch all hotel rooms for the given location
            const hotelRooms = await db.hotelRooms.where({ locationId: hotelLocationId }).toArray();

            // Fetch all bookings that overlap with the requested date range
            const bookingReservations = await db.hotelReservations.where({ hotelLocationId: hotelLocationId })
                .and((reservation) => {
                    const checkInDate = new Date(reservation.checkInDate!);
                    const checkOutDate = new Date(reservation.checkOutDate!);
                    return (
                        (isBefore(checkInDate, new Date(endDate)) || isEqual(checkInDate, new Date(endDate))) &&
                        (isAfter(checkOutDate, new Date(beginDate)) || isEqual(checkOutDate, new Date(beginDate)))
                    );
                })
                .toArray();

            // Extract the room IDs that are booked during the given date range
            const bookedRoomIds = bookingReservations.map(reservation => reservation.roomId);

            // Filter out rooms that are already booked
            return  hotelRooms.filter(room => !bookedRoomIds.includes(room.roomId));

        } catch (error) {
            throw error;
        }
    }
}

export default HotelRoomClass;
