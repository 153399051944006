import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { hotelRoomStatusQuery } from '../../utilities/reactQueryUtils';
import { changeDateFormat, isAppOnline } from '../../utilities/utilFunctions';
import useUserData from '../../hooks/customHooks';
import { TAppState, THotelRoomStatus } from '../../utilities/types';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { Button } from 'primereact/button';
import { GeneralPageProps, Loader } from '../../utilities/components/UtilityComponents';
import HotelRoomClass from '../../utilities/classObjects/HotelRoom';
import Settings from '../../utilities/classObjects/Settings';


type TRoomStatusState = {
    roomsList: THotelRoomStatus[],
    statusDate: Nullable<string | Date | Date[]>,
    isLoading: boolean,
    appState:TAppState,
}
const INITIAL_STATE: TRoomStatusState = {
    roomsList: [],
    statusDate: new Date(),
    isLoading: false,
    appState: 'onLine'
};

/*
component helps to organise all available rooms in a location and
display the current status of the room either it is occupied, available
and any of the scheduled status of a room.
 */
const hotelRoomObject=new HotelRoomClass();

const settings=new Settings();
const RoomStatus = () => {
    const queryClient = useQueryClient();
    const [userData] = useUserData();
    const [state, setState] = useState<TRoomStatusState>(INITIAL_STATE);
    const toastRef = useRef(null);
    useEffect(() => {
        const initRoomStatus = async () => {
            const appSettings= await settings.getLocationSettings();
            const roomsStatus = isAppOnline(state.appState)?await hotelRoomStatusQuery(queryClient
                    , changeDateFormat(state.statusDate as Date), userData.hotelLocationId):
                await hotelRoomObject.getOfflineRoomStatus(changeDateFormat(state.statusDate as Date),userData.hotelLocationId);
            setStateValue({
                roomsList: roomsStatus,
                appState:appSettings.connectivityState
            });
        };
        initRoomStatus().catch(console.error);
    }, []);
    const setStateValue = (stateValues: Partial<TRoomStatusState>) => {
        setState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const getRoomColor = (status: string) => {
        switch (status) {
            case 'Confirmed':
                return 'bg-yellow-500';
            case 'Checked-in':
                return 'bg-orange-500';
            case 'Checked-out':
                return 'bg-pink-900';
            case 'Pending':
                return 'bg-pink-300';
            default:
                return 'bg-green-500';
        }
    };
    const queryRoomStatus = async () => {
        try {
            setStateValue({ isLoading: true });
            const roomsStatus = isAppOnline(state.appState)?await hotelRoomStatusQuery(queryClient
                , changeDateFormat(state.statusDate as Date), userData.hotelLocationId):
                await hotelRoomObject.getOfflineRoomStatus(changeDateFormat(state.statusDate as Date),userData.hotelLocationId);
            setStateValue({ roomsList: roomsStatus });
        } catch (error) {
            console.log(error);
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="grid p-fluid">
                <div className="col-3">
                    <div className="field">
                        <label htmlFor="checkInDate">Select Dates</label>
                        <Calendar value={state.statusDate} selectionMode="single" onChange={(e) => setStateValue({ statusDate: e.value })} />
                    </div>
                </div>
                <div className="col-1">
                    <Button className="mt-4" onClick={queryRoomStatus}>Query Status</Button>
                </div>
            </div>
            <div className="grid p-fluid">
                {
                    state.roomsList.map((room:THotelRoomStatus, index) => {
                        return <div className="col-3 cursor-pointer" key={`RM${index}`}>
                            <div className={`card ${getRoomColor(room.roomStatus)}`}>
                                <div className="card-body h-5rem">
                                    <h6>{`${room.roomNumber} - ${room.roomStatus}`}</h6>
                                    <p>{room.roomStatus!=='Available'?`${changeDateFormat(room.checkInDate as Date)} - ${changeDateFormat(room.checkOutDate)}`:''}</p>
                                </div>
                            </div>
                        </div>;
                    })
                }
            </div>
        </>
    );
};
export default RoomStatus;
